import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import Layout from 'manage-tritag/components/layout'
import Spinner from 'manage-tritag/components/loading/spinner'
import EnhancedTable from 'manage-tritag/components/table/enhanced-table'
import EnhancedTableFilter from 'manage-tritag/components/table/enhanced-table/enhanced-table-filter'
import {
  Division,
  QueryParams,
  useLazyGetDivisionsQuery,
} from 'manage-tritag/services/api/endpoints/divisions'
import { navigate } from 'gatsby'

interface HeadCell {
  disablePadding: boolean
  id: keyof Division
  label: string
  numeric: boolean
}

const DivisionPage = () => {
  const { user } = useAuth0()
  const [params, setParams] = useState<QueryParams>({
    offset: 0,
    limit: 100000,
    search: '',
  })
  const [trigger, result] = useLazyGetDivisionsQuery()
  const [isLoading, setIsLoading] = useState(true)
  const { isLoading: dataLoading, data: divisions } = result

  useEffect(() => {
    trigger(params)
  }, [params])

  useEffect(() => {
    if (user) {
      if (user['https://tritagrugby.com/user_metadata']) {
        if (user['https://tritagrugby.com/user_metadata'].activeRole) {
          if (
            ![1, 2].includes(
              user['https://tritagrugby.com/user_metadata'].activeRole.type,
            )
          ) {
            navigate('/')
          } else {
            setIsLoading(false)
          }
        }
      }
    }
  }, [user])

  const headCells: HeadCell[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'gender',
      numeric: false,
      disablePadding: false,
      label: 'Gender',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Status',
    },
  ]
  const bodyCells = ['name', 'gender', 'status']
  return (
    <Layout label1="Divisions" url="/divisions">
      {isLoading || dataLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 145px);',
          }}
        >
          <Spinner size={40} color="#008174" />
        </Box>
      ) : (
        divisions && (
          <>
            <EnhancedTableFilter params={params} setParams={setParams} />
            <EnhancedTable
              tableTitle="Seasons"
              uniqueIdentifier="_id"
              rows={divisions.map((division, index) => ({
                ...division,
                id: index,
                status: division.status ? 'Active' : 'Inactive',
              }))}
              headCells={headCells}
              bodyCells={bodyCells}
            />
          </>
        )
      )}
    </Layout>
  )
}

export default withAuthenticationRequired(DivisionPage)
